import { useState } from "react"
import styled from "styled-components"

import { FilterQueryBox } from "src/components/Filter/FilterQueryBox"
import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { useAppData } from "src/context/useAppData"
import { IFilter } from "src/data/filters/types/filterTypes"
import { useFetchOrganizations } from "src/data/organizations/queries/organizationQueries"
import { useUrlParam } from "src/hooks/useUrlParam"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MDialog } from "src/ui/Dialog/MDialog"
import { GridTable } from "src/ui/GridTable/GridTable"
import { MainView } from "src/ui/Layout/MainView"
import { InternalLink } from "src/ui/Link/InternalLink"
import { spacing } from "src/ui/spacing"

export function OrganizationsList() {
  const { t, langKeys } = useTranslate()
  const { limit, offset, setOffset } = useUrlPager()
  const { setActiveOrgId } = useAppData()
  const { navigate } = useRouter()

  const [open, setOpen] = useState(false)
  const [selectedOrganization, setSelectedOrganization] = useState({
    orgId: "",
    orgName: "",
  })

  const TextOrgFilter: IFilter = {
    id: "name",
    name: (t) => t(langKeys.search),
    value: "",
    unique: true,
  }
  const { value: name } = useUrlParam(TextOrgFilter.id)

  const fetchOrganizations = useFetchOrganizations({
    params: { limit, offset, name },
  })

  const organizations = fetchOrganizations.data?.organizations || []

  const rows: JSX.Element[] = organizations.map((org) => {
    return (
      <div key={org.id}>
        <InternalLink
          onClick={(e) => {
            e.preventDefault()
            setOpen(true)
            setSelectedOrganization({ orgId: org.id, orgName: org.name })
          }}
          to="#"
        >
          {org.name}
        </InternalLink>
      </div>
    )
  })

  return (
    <MainView
      title={t(langKeys.organizations_header)}
      titleBarProps={{
        showBottomBorder: false,
      }}
      size="unset"
    >
      <section>
        <FilterQueryBox
          searchFieldFilter={TextOrgFilter}
          bottomMargin={spacing.L}
        />

        <GridTable
          header={[<div key={"organization"}>{t(langKeys.organization)}</div>]}
          rows={rows}
          loading={fetchOrganizations.isLoading}
          useFallbackResponsiveMode
        />
        <PagerWrapper>
          <Pager
            limit={limit}
            offset={offset}
            setOffset={setOffset}
            totalCount={fetchOrganizations.data?.paging.total_count}
          />
        </PagerWrapper>
      </section>

      <MDialog
        title={t(langKeys.organizations_switch_organization_title)}
        open={open}
        onClose={() => setOpen(false)}
        description={t(langKeys.organizations_switch_organization_body, {
          name: selectedOrganization.orgName,
        })}
        confirmLabel={t(langKeys.switch)}
        onConfirm={() => {
          setActiveOrgId(selectedOrganization.orgId)
          navigate(Routes.Organization.location(), {
            orgIdOverride: selectedOrganization.orgId,
          })
          setOpen(false)
        }}
      />
    </MainView>
  )
}

const PagerWrapper = styled.div`
  margin-top: ${spacing.XL};
`
